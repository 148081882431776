<template>
  <section class="section container">

    <div class="columns">
      <div class="column is-3">
        <Menu @getdoc='getPageId' @gettitle='getPageTitle' />
      </div>

      <div v-if="charges_data_loaded" class="column">
        <div v-if="doc.plan === `standard` && payment_date" class="column">
          <Downgrade :pagedata="doc" :chargesdata="charges_doc" @close="closeModal" />
        </div>
        <div v-else class="column">
          <Upgrade :pagedata="doc" :chargesdata="charges_doc" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import Downgrade from '@/components/payment/Downgrade.vue'
import Upgrade from '@/components/payment/Upgrade.vue'
import Config from '../config.js'

export default {
  mixins: [Menu],
  components: {
    Menu,
    Downgrade,
    Upgrade
  },
  data () {
    return {
      amount: Config.AMOUNT,
    }
  },
  methods: {
    getPageId(value) {
      this.doc = value
    },
    getPageTitle(value) {
      this.itemNames = value
    },
  },
  watch: {

  },
}
</script>

<style scoped>
</style>
